
const frontEndSkills = {
    html: {
        name: "HTML",
        fileName: 'html'
    },
    css: {
        name: "CSS",
        fileName: 'css',
    },
    javascript: {
        name: "JavaScript",
        fileName: 'javascript',
    },
    typescript: {
        name: "TypeScript",
        fileName: 'typescript',
    },
    react: {
        name: "React",
        fileName: 'react',
    },
    redux: {
        name: "Redux",
        fileName: 'redux',
    },
    responsiveDesign: {
        name: "Responsive Design",
        fileName: 'responsive',
    },
    UX: {
        name: "UX Design",
        fileName: 'uxdesign',
    },
    design: {
        name: "Design Background",
        fileName: 'design',
    },
    next: {
        name: 'Next.js',
        fileName: 'next',
    },
    bootleg: {
        name: 'Bootstrap',
        fileName: 'bootstrap'
    },
    tailwind: {
        name: 'Tailwind',
        fileName: 'tailwind'
    }

}

const backEndSkills = {
    nodejs: {
        name: "Node.js",
        fileName: "nodejs"
    },
    express: {
        name: "Express.js",
        fileName: 'express',
    },
    RESTful: {
        name: "RESTful API",
        fileName: 'restful',
    },
    PostgreSQL: {
        name: "PostgreSQL",
        fileName: 'postgresql',
    },
    SQL: {
        name: "SQL",
        fileName:'sql',
    },
    dataStructures: {
        name: "Data Structures",
        fileName: 'dataStructures',
    },
    webSecurity: {
        name: "Web Security",
        fileName: 'webSecurity',
    }
}

const devOps = {
    github : {
        name: "Git & Version Control",
        fileName: 'github',
    },
    netlify: {
        name: "Netlify",
        fileName: 'netlify',
    },
    cloudflare: {
        name: "Cloudflare",
        fileName: 'cloudflare',
    },
    render: {
        name: "Render",
        fileName:'render',
    }
}

const api = {
    api: {
        name: "API Design & Integration",
        fileName: 'api',
    },
    json: {
        name: "JSON",
        fileName: 'json',
    },
    authentication: {
        name: "Authentication (OAuth, JWT)",
        fileName: 'authentication',
    }
}

const testing = {
    jest: {
        name: "Jest",
        fileName: 'jest',
    },
    mocha: {
        name: "Mocha",
        fileName:'mocha',
    },
    postman: {
        name: "Postman",
        fileName: 'postman',
    }
}

const languages = {
    python: {
        name: "Python (Basic)",
        fileName: 'python',
    },
    ruby: {
        name: "Ruby (Basic)",
        fileName: 'ruby',
    },
    csharp: {
        name: 'C# (Basic)',
        fileName: 'csharp',
    }
}

export const skills = [frontEndSkills, backEndSkills, devOps, api, testing, languages];