
export const introHighlights = {
    name: {
        text: "Jon Porter",
        hoverText: "It's not just a random name<br/> all over the website!"
    },
    background: {
        text: "3D Design/Product Design Engineering",
        hover: ""
    },
    UXUI: {
        text: "UX/UI Design",
        hover: ""
    },
    codecademy: {
        text: "Codecademy",
        hover: ""
    },
    cSharp: {
        text: "C#",
        hover: ""
    },
    python: {
        text: "Python",
        hover: ""
    },
    frontEnd: {
        text: "Front End Development and Web Design",
        hover: ""
    },
    designsPop: {
        text: "designs pop more",
        hover: ""
    },
    easterEggs: {
        text: "easter eggs",
        hover: ""
    },
    interests: {
        text: "movies, read books, draw, game, hang with friends",
        hover: ""
    },
    scout: {
        text: "cuddle my cat, Scout",
        noImages: 7
    }
}